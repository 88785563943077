import React from "react";

// import img
import Image from "../assets/img/about.webp";

const About = () => {
  return (
    <section className="section bg-secondary" id="about">
      <div className="container mx-auto">
        <div className="flex flex-col xl:flex-row gap-24">
          <img
            className="object-cover h-full w-[866px] md:mx-auto lg:mx-0 rounded-2xl"
            src={Image}
            alt=""
          />
          <div className="flex flex-col items-center text-center lg:items-start lg:text-left">
            <div className="flex flex-col">
              <h2 className="text-3xl lg:text-4xl font-medium lg:font-extrabold mb-3 before:content-about relative before:absolute before:opacity-40 before:-top-[2rem] before:hidden before:lg:block">
                Sajib_Ahmed
              </h2>
              <p className="mb-4 text-accent">
                freelance Frontend Web Developer
              </p>
              <hr className="mb-8 opacity-5" />
              <p className="mb-8">
                Hi there! I'm Sajib_Ahmed student of Computer Science & Software Engineering. And I'm a full-stack developer. My aim in life has always been to become a successful software engineer. <br />
                <br />
                “The computer was born to solve problems that did not exist before.” – (Bill Gates)
              </p>
            </div>
            <button className="btn btn-md bg-accent hover:bg-secondary-hover transition-all">
              <a href="https://t.me/developer_sajib" target={"_blank"}>
                Contact me
              </a>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
